<template>
  <div class="Compatibility xi">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>配伍</h3>
        <p>PEI WU</p>
      </div>
    </div>
    <div class="content">
      <div class="content-box clearfix">
        <div class="pei-top">
          <h3>用药查询</h3>
          <div class="pei-search">
            <el-autocomplete v-model="state" :fetch-suggestions="querySearchAsync" placeholder="输入关键词" @select="handleSelect" clearable></el-autocomplete>
          </div>
          <ul class="pei-list flex-start flex-wrap">
            <li v-for="(item, index) in fruit" :key="index" class="on clearfix flex-center">
              <el-checkbox class="left" :checked="item.state == '1' ? true : false" @change="(checked) => checkChange(checked, item.id ? item.id : item.hashKey)"></el-checkbox>
              <div class="name left" :title="item.value">
                {{ item.value }}
                <!-- <a href="javascript:void(0);" target="_blank"> {{ item.value }}</a> -->
              </div>
              <div class="del-btn right" @click="toRemoveCompar(item.hashKey)">
                <i class="el-icon-close"></i>
              </div>
            </li>
          </ul>
          <div class="pei-sel">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 15px">全选 </el-checkbox>
            <el-checkbox-group v-model="checkedCk" @change="handleCheckedCksChange" style="display: inline">
              <el-checkbox v-for="city in ck" :label="city" :key="city">{{ city }}</el-checkbox>
            </el-checkbox-group>
            <div class="btn-group">
              <el-button type="primary" size="small" round @click="compatibility()">用药审查</el-button>
              <el-button size="small" round @click="removeAll()">全部清空</el-button>
            </div>
          </div>
        </div>
        <div class="pei-bottom">
          <ul class="tab-tilte clearfix">
            <li @click="cur = 0" :class="[{ active: cur == 0 }, total > 0 ? '' : 'greenbg']">
              <i :class="total > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>全部</em><span>{{ total }}</span>
            </li>
            <li @click="cur = 1" :class="[{ active: cur == 1 }, similarTotal > 0 ? '' : 'greenbg']">
              <i :class="similarTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>重复用药</em><span>{{ similarTotal }}</span>
            </li>
            <li @click="cur = 2" :class="[{ active: cur == 2 }, compatibilityTotal > 0 ? '' : 'greenbg']">
              <i :class="compatibilityTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>相互作用</em><span>{{ compatibilityTotal }}</span>
            </li>
            <li @click="cur = 3" :class="[{ active: cur == 3 }, crowdTotal > 0 ? '' : 'greenbg']">
              <i :class="crowdTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>特殊人群</em><span>{{ crowdTotal }}</span>
            </li>
            <li @click="cur = 4" :class="[{ active: cur == 4 }, dietTotal > 0 ? '' : 'greenbg']">
              <i :class="dietTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>饮食禁忌</em><span>{{ dietTotal }}</span>
            </li>
            <li @click="cur = 5" :class="[{ active: cur == 5 }, avoidTotal > 0 ? '' : 'greenbg']">
              <i :class="avoidTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>用药禁忌</em><span>{{ avoidTotal }}</span>
            </li>
          </ul>
          <div class="tab-content">
            <ul v-show="cur == 0">
              <li v-for="(similar, index) in similarList" :key="index + '1'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <h3>
                    【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-html="similarhtmlRaw(similar)"></p>
                </div>
              </li>
              <li v-for="(compare, index) in compatibilityList" :key="index + '2'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div v-if="compare.compatibilityType == 7">
                    <h3 v-for="(tip, index1) in compare.tips" :key="index1">
                      【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                    </h3>
                  </div>
                  <div v-else>
                    <h3>
                      【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div v-if="compare.tips" class="peitab-mes">
                  <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + '3'">
                    <div v-if="types.keys <= 3">
                      <p v-for="(val, index2) in types.value" :key="index2 + '4'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <div v-if="types.keys == 4">
                      <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + '6'">
                        <p v-html="namehtmlraw(val)"></p>
                      </div>
                    </div>
                    <div v-if="types.keys == 5">
                      <p v-for="(val, index2) in types.value" :key="index2 + '7'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <div v-if="types.keys == 7">
                      <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                    </div>
                    <div v-if="types.keys == 8">
                      <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + '8'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail" @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">查看详情</p>
                  </div>
                </div>
                <div v-else class="peitab-mes">
                  <div>
                    <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                    <!--<p>暂未发现本组用药的不妥之处，但不表示本组用药一定是安全的，详情请<a class="linkDetail" @click="toCompatibilityDetail(compare.sourceType,compare.sourceHashKey,compare.targetType,compare.targetHashKey)">查看说明书</a></p>-->
                  </div>
                </div>
              </li>
              <li v-for="(ins, index) in getcrowdList()" :key="index + '9'">
                <div v-for="(item3, index1) in ins.value" :key="index1 + 'a'">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【特殊人群1】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <p v-for="(val, index2) in item3.items" :key="index2 + 'b'" v-html="contenthtmlRaw(val)"></p>
                  </div>
                </div>
              </li>
              <template v-if="getNumByType(instructions, 2) > 0">
                <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 'e'">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【饮食禁忌】<span>{{ ins.name }} </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'f'">
                      <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                    </div>
                    <div v-for="(item, index1) in dietGroup(ins.items.filter((i) => i.target != null))" :key="index1 + 'v'">
                      <p v-html="contenthtmlRaw(item, true)"></p>
                    </div>
                  </div>
                </li>
              </template>
              <template v-if="getNumByType(instructions, 1) > 0">
                <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'c'" v-if="getNumByType(instructions, 1) > 0">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【用药禁忌】<span>{{ ins.name }} </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <p v-for="(item, index1) in ins.items" :key="index1 + 'd'" v-html="contenthtmlRaw(item)"></p>
                  </div>
                </li>
              </template>
            </ul>
            <div v-show="cur == 1">
              <li v-for="(similar, index) in similarList" :key="index + 'h'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <h3>
                    【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-html="similarhtmlRaw(similar)"></p>
                </div>
              </li>
            </div>
            <div v-show="cur == 2">
              <li v-for="(compare, index) in compatibilityList" :key="index + 'i'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div v-if="compare.compatibilityType == 7">
                    <h3 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                      【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                    </h3>
                  </div>
                  <div v-else>
                    <h3>
                      【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div v-if="compare.tips" class="peitab-mes">
                  <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                    <div v-if="types.keys <= 3">
                      <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <div v-if="types.keys == 4">
                      <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + 'n'">
                        <p v-html="namehtmlraw(val)"></p>
                      </div>
                    </div>
                    <div v-if="types.keys == 5">
                      <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <div v-if="types.keys == 7">
                      <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                    </div>
                    <div v-if="types.keys == 8">
                      <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + 'p'" v-html="namehtmlraw(val)"></p>
                    </div>
                    <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail" @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">查看详情</p>
                  </div>
                </div>
                <div v-else class="peitab-mes">
                  <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                  <!--<p>暂未发现本组用药的不妥之处，但不表示本组用药一定是安全的，详情请<a class="linkDetail" @click="toCompatibilityDetail(compare.sourceType,compare.sourceHashKey,compare.targetType,compare.targetHashKey)">查看说明书</a></p>-->
                </div>
              </li>
            </div>
            <div v-show="cur == 3">
              <li v-for="(ins, index) in getcrowdList()" :key="index + 'q'">
                <div v-for="(item3, index1) in ins.value" :key="index1 + 'r'">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【特殊人群】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <p v-for="(val, index2) in item3.items" :key="index2 + 's'" v-html="contenthtmlRaw(val)"></p>
                  </div>
                </div>
              </li>
            </div>
            <div v-show="cur == 4">
              <template v-if="getNumByType(instructions, 2) > 0">
                <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 't'">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【饮食禁忌】<span>{{ ins.name }} </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'u'">
                      <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                    </div>
                    <div v-for="(item, index1) in dietGroup(ins.items.filter((i) => i.target != null))" :key="index1 + 'v'">
                      <p v-html="contenthtmlRaw(item, true)"></p>
                    </div>
                  </div>
                </li>
              </template>
            </div>
            <div v-show="cur == 5">
              <template v-if="getNumByType(instructions, 1) > 0">
                <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'w'" v-if="getNumByType(instructions, 1) > 0">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div>
                      <h3>
                        【用药禁忌】<span>{{ ins.name }} </span>
                      </h3>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="peitab-mes">
                    <p v-for="(item, index1) in ins.items" :key="index1 + 'x'" v-html="contenthtmlRaw(item)"></p>
                  </div>
                </li>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import $ from "jquery";
import hdCom from "../components/header"; //引入组件
import ftCom from "../components/footer"; //引入组件
import { getDrugOrHerbal } from "../utils/diseaseUtility";
import { getckOptionsNames, addCompare, valueConvertName, nameConvertValue, getCompatibilityList, removeCompare, updateCompare, removeCompareAll, namehtmlraw, tipGroup, dietGroup, contenthtmlRaw, getcrowdList } from "../utils/Compatibility";
import { Compatibility } from "../components/Compatibility/Compatibility";
import { StatisticsTime } from "../components/statisticsTime/StatisticsTime";
import { getPropertyCount, getCount } from "../utils/extend";
import { tocompareDetail } from "../utils/relation";
export default {
  name: "Compatibility",
  components: {
    hdCom,
    ftCom,
  },
  data() {
    var compatibility = new Compatibility(this.TokenClient, this.Services.Drug);
    var staticTime = new StatisticsTime(this.TokenClient, this.Services.Statics);
    var myDate = new Date();
    return {
      checkAll: true,
      checkedCk: getckOptionsNames(),
      checked: true,
      ck: getckOptionsNames(),
      cur: 0, //默认选中第一个tab
      showCourseInfo: false,
      state: "",
      fruit: getCompatibilityList(),
      compatibilityList: [], //配伍
      similarList: [], //同类药
      instructions: [], // 用药须知    禁忌1   饮食2   老人3    儿童4    妊娠5   哺乳6
      types: [],
      crowdList: [], // 特殊人群
      similarTotal: 0, //同类药条数
      compatibilityTotal: 0, //配伍条数
      crowdTotal: 0, //老人、儿童、妊娠、哺乳 总条数
      dietTotal: 0, //饮食条数
      avoidTotal: 0, //禁忌条数
      total: 0, //总条数
      selectChecks: this.$route.query.selectChecks,
      compatibilityDomain: compatibility,
      browseTime: 0, // 浏览时长初始值为 0
      dateNow: null, //开始浏览时间,
      clearTimeSet: null,
      staticTimeDomain: staticTime,
      oldName: null,
      currentlyAvailable: false,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      // if (val) this.selectChecks = '0|1|2|3|4|5|6'
      if (val) this.types = [0, 1, 2, 3, 4, 5, 6];
      else this.types = "";
      this.checkedCk = val ? this.ck : [];
      this.isIndeterminate = false;
    },
    handleCheckedCksChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.ck.length;
      this.selectCheckProcess(value);
    },
    //列表项----点击展开收起
    showCourseInfoList(e) {
      const target = e.currentTarget;
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle();
      // 切换样式-图标
      $(target).find("i").toggleClass("el-icon-arrow-down").addClass("el-icon-arrow-up");
      if ($(target).siblings().is(":hidden")) {
        $(target).find(".show-text").html("展开");
      } else {
        $(target).find(".show-text").html("收起");
      }
    },
    loadAll(queryString) {
      var data = getDrugOrHerbal(queryString);
      return data;
    },
    querySearchAsync(queryString, cb) {
      var _this = this;
      if (queryString != "") {
        let callBackArr = [];
        _this.compatibilityDomain.SearchAll(
          queryString,
          function (data) {
            data.data.forEach((item) => {
              callBackArr.push({
                value: item.name,
                id: item.result,
                type: item.type,
              });
            });
            if (callBackArr.length == 0) {
              cb([{ value: "暂无数据", price: "暂无数据" }]);
            } else {
              cb(callBackArr);
            }
          },
          function (error) {
            console.log("配伍请求异常:", error);
          }
        );
      }
    },
    handleSelect(item) {
      if (this.fruit.length >= 1) {
        if (this.fruit.some((i) => i.hashKey == item.id)) {
          return false;
        }
      } else {
        this.fruit = [];
      }
      item.state = 1;
      // this.fruit.push(item);
      addCompare(item.id, item.value, 1, item.type);
      this.fruit = getCompatibilityList();
      this.state = "";
    },
    toRemoveCompar(hashKey) {
      // this.fruit.map((res)=>{
      //   console.log(res.hashKey)
      // })
      removeCompare(hashKey);
      this.fruit = getCompatibilityList();
    },
    checkChange(check, hashKey) {
      updateCompare(hashKey, check);
    },
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    //用药审查
    compatibility() {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      if (!this.types) {
        this.$message.error("请选择配伍类型！");
        return false;
      }
      var _this = this;
      _this.compatibilityList = [];
      _this.similarList = [];
      _this.instructions = [];
      this.dietTotal = 0;
      this.avoidTotal = 0;
      this.total = 0;
      this.crowdTotal = 0;
      this.compatibilityTotal = 0;
      this.similarTotal = 0;
      _this.compatibilityDomain.Compatibilitys(
        _this.types,
        function (data) {
          _this.putTime();
          _this.oldName = getCompatibilityList()
            .filter((x) => x.state === "1")
            .map((x) => x.value)
            .join("---");
          _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7);
          _this.similarList = data.data.similar;
          _this.instructions = data.data.instructions;
          _this.types = data.data.types;
          _this.totalprocess();
          _this.staticTimeInit();
        },
        function (error) {
          console.log("配伍请求异常:", error);
        }
      );
    },
    getNumByType(array, type) {
      let num = 0;
      if (array.filter((i) => i.type == type).length > 0) {
        num = array.filter((i) => i.type == type)[0].items.length;
      }
      return num;
    },
    //条数处理
    totalprocess() {
      this.similarTotal = this.types.indexOf(0) != -1 && this.similarList != null ? this.similarList.length : 0;
      this.compatibilityTotal = this.types.indexOf(0) != -1 ? this.compatibilityList.length : 0;
      this.crowdTotal = this.getNumByType(this.instructions, 3) + this.getNumByType(this.instructions, 4) + this.getNumByType(this.instructions, 5) + this.getNumByType(this.instructions, 6);

      this.dietTotal = this.getNumByType(this.instructions, 2);
      console.log(this.getNumByType(this.instructions, 2));
      this.avoidTotal = this.getNumByType(this.instructions, 1);
      this.total = this.avoidTotal + this.dietTotal + this.crowdTotal + this.compatibilityTotal + this.similarTotal;
    },
    //tip分组
    tipGroup(list) {
      return tipGroup(list);
    },
    namehtmlraw(item) {
      return namehtmlraw(item);
    },
    //内容HTML转换
    contenthtmlRaw(item, isdiet = false) {
      return contenthtmlRaw(item, isdiet);
    },
    //饮食禁忌 分组
    dietGroup(list) {
      return dietGroup(list);
    },
    //特殊人群列表
    getcrowdList() {
      return getcrowdList(this.instructions);
    },
    //选择处理
    selectCheckProcess(stringArr) {
      this.types = nameConvertValue(stringArr);
    },
    //跳转到详情
    toCompatibilityDetail(sourceType, sourceHashKey, targetType, targetHashKey) {
      tocompareDetail(sourceType, sourceHashKey, targetType, targetHashKey);
    },
    removeAll() {
      this.compatibilityList = [];
      this.similarList = [];
      this.instructions = [];
      this.dietTotal = 0;
      this.avoidTotal = 0;
      this.total = 0;
      this.crowdTotal = 0;
      this.compatibilityTotal = 0;
      this.similarTotal = 0;
      removeCompareAll();
      this.fruit = [];
    },
    similarhtmlRaw(item) {
      var info = "";
      if (item.sourceDrugCategory != item.targetDrugCategory) {
        // var reg = new RegExp(item.sourceDrugComposition.replace(/\s/g, "").split("").join("|"), "ig");
        // var reg1=new RegExp(',','g')
        // console.log(item.targetDrugComposition.match(reg).join().replace(reg1,''))
        info = `${item.sourceDrugName}:${item.sourceDrugComposition}<br> ${item.targetDrugName}:${item.targetDrugComposition} <br> ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `;
      } else {
        info = `${item.sourceDrugName} 与 ${item.targetDrugName} 同属于 ${item.sourceDrugCategory}(${item.sourceDrugCategoryName})`;
      }
      // info=`${item.sourceDrugName}:${item.sourceDrugComposition}<br>
      //     ${item.targetDrugName}:${item.targetDrugComposition} <br>
      //     ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `
      return info;
    },
    //浏览时长初始化
    staticTimeInit() {
      ///开始计时
      this.browseTime = 0;
      //开始浏览时间
      this.dateNow = this.$moment(this.myDate).utcOffset(480).format("YYYY-MM-DD HH:mm:ss");
      this.setTime(); // 页面加载完成后开始计时
    },
    putTime() {
      clearInterval(this.clearTimeSet); // 清除定时器
      var _this = this;
      if (_this.dateNow != null) {
        _this.staticTimeDomain.Put(
          "compatibility",
          _this.oldName,
          "00000000-0000-0000-0000-000000000000",
          0,
          0,
          _this.dateNow,
          _this.browseTime,
          function (data) {},
          function (err) {
            console.log(err);
          }
        );
      }
    },
    setTime() {
      //设置定时器
      this.clearTimeSet = setInterval(() => {
        this.browseTime++;
      }, 1000);
    },
  },
  mounted() {
    //this.restaurants = this.loadAll();
    if (this.selectChecks) {
      this.types = JSON.parse(this.selectChecks);
      this.checkedCk = valueConvertName(this.types);
      this.compatibility();
    } else {
      this.types = [0, 1, 2, 3, 4, 5, 6];
    }
  },
  beforeDestroy() {
    clearInterval(this.clearTimeSet); // 离开页面后清除定时器
    this.putTime();
  },
};
</script>

<style scoped>
@import "../assets/css/header.css";
@import "../assets/css/footer.css";

.banner {
  height: 116px;
  background: url("../assets/img/jbk_banner.png") center no-repeat;
}

.banner-text {
  width: 1200px;
  margin: 0 auto;
}

.banner-text h3 {
  padding-top: 25px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 18px;
  color: #ffffff;
}

/*content*/
.content {
  background: #f8f8f8;
}

.content-box {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}

.pei-top {
  background: #ffffff;
  padding: 30px 200px;
}

.pei-top h3 {
  font-size: 24px;
  color: #333333;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
}

.pei-search {
  margin: 0 auto;
  width: 745px;
  border: 1px solid #00ab98;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  overflow: hidden;
}

.pei-search .el-autocomplete {
  width: 720px;
  border: none;
  padding: 0 20px;
  box-sizing: border-box;
  background: none;
  line-height: 50px;
  font-size: 16px;
  vertical-align: top;
}

.pei-search button {
  width: 60px;
  background: none;
}

.pei-search button i {
  font-size: 20px;
}

.pei-list {
  max-width: 745px;
  margin: 20px auto;
}

.pei-list li {
  float: left;
  /* margin: 15px 0;
        padding: 0 28px; */
  width: 50%;
  box-sizing: border-box;
}

.pei-list li .name {
  width: 245px;
  line-height: 45px;
  border-bottom: 1px solid #cccccc;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pei-list li .name a {
  width: 100%;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.del-btn {
  line-height: 45px;
  padding: 0 5px;
  cursor: pointer;
}

.del-btn i {
  font-size: 18px;
  font-weight: 700;
  padding: 2px;
}

.del-btn:hover i {
  color: #1b9183;
}

.pei-sel .btn-group {
  margin-left: 20px;
  display: inline-block;
}

/*    */
.pei-bottom {
  background: #ffffff;
  padding: 30px;
  margin-top: 20px;
}

.tab-tilte {
}

.tab-tilte li {
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-right: 15px;
}

.tab-tilte li em {
  font-style: normal;
  margin: 0 5px;
  font-size: 14px;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte li.greenbg {
  border: 1px solid #1fb7a6 !important;
}

.tab-tilte li.greenbg em {
  color: #1fb7a6 !important;
}

.tab-tilte li.active {
  border: 1px solid #f46e6e;
}

.tab-tilte li.active em {
  color: #f46e6e;
}

.tab-tilte li i {
  color: #f46e6e;
}

.tab-tilte li.active span {
  color: #f46e6e;
}

.tab-tilte li.greenbg i {
  color: #1fb7a6;
}

.tab-tilte li.greenbg span {
  color: #1fb7a6;
}

.tab-content {
  margin-top: 30px;
}

.tab-content ul li {
}

.tab-content ul li:first-child {
}

.peitab-tit {
  position: relative;
  border-top: 1px solid #e5e5e5;
  padding: 20px 15px;
}

.peitab-tit h3 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 18px;
}

.peitab-tit h3 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.peitab-mes {
  display: none;
  width: 830px;
  padding: 10px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}
</style>
<style>
.Compatibility .el-checkbox {
  margin: 15px 15px 0 0;
}

.Compatibility .pei-list .el-checkbox {
  margin: 0 15px;
}

.Compatibility .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #ededed;
}

.pei-search .el-input__inner {
  border: none !important;
}

.Compatibility .el-checkbox__inner::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 6px;
  top: 3px;
  width: 4px;
}

.Compatibility .el-checkbox__input:hover {
  border-color: #dcdfe6;
}

.Compatibility .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #dcdfe6;
}
</style>
